import React, { useEffect, useState } from 'react';
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { useNavigate } from "react-router-dom";
import { FaHome } from 'react-icons/fa';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import NewspaperRoundedIcon from '@mui/icons-material/NewspaperRounded';
import RateReviewIcon from "@mui/icons-material/RateReview";
import axios from 'axios';
import CalculateRounded from '@mui/icons-material/CalculateRounded';

const CustomBottomNavigation = () => {
  const [value, setValue] = useState(0);
  const [menuType, setMenuType] = useState(null); // Pour stocker le type de menu à afficher
  const navigate = useNavigate();

  useEffect(() => {
    // Faites la requête à votre API pour déterminer quel menu afficher
    const url = `${process.env.REACT_APP_API_ROOT_V1}/homepage`;
    axios.get(url)
      .then(response => {
        // Déterminer le type de menu selon la réponse de l'API
        setMenuType(response.data.status);
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des données de la page d'accueil :", error);
        // En cas d'erreur, on peut par défaut définir un type de menu
        setMenuType('services');
      });
  }, []);

  const handleNavigation = (label) => {
    switch (label) {
      case "Accueil":
        navigate("/");
        break;
      case "Simulateur":
        navigate("/simulateur");
        break;
      case "Services":
        navigate("/services");
        break;
      case "Articles":
        navigate("/articles");
        break;
      case "avis":
        const url = "https://docs.google.com/forms/u/1/d/e/1FAIpQLSeD5niFBOtyYWtMZ62d-cjxhW_8M9YxfqDrVaG8-Y47Dq78Kg/viewform";
        window.open(url, '_blank');
        break;
      default:
        break;
    }
  };

  // Configuration des éléments de menu selon le type
  const menuSimulateur = [
    { label: 'Accueil', icon: <FaHome />, onClick: () => handleNavigation('Accueil') },
    { label: 'Services', icon: <TravelExploreIcon />, onClick: () => handleNavigation('Services') },
    { label: 'Blog', icon: <NewspaperRoundedIcon />, onClick: () => handleNavigation('Articles') },
    { label: 'Avis', icon: <RateReviewIcon />, onClick: () => handleNavigation('avis') }
  ];

  const menuServices = [
    { label: 'Accueil', icon: <FaHome />, onClick: () => handleNavigation('Accueil') },
    { label: 'Simulateur', icon: <CalculateRounded />, onClick: () => handleNavigation('Simulateur') },    
    { label: 'Blog', icon: <NewspaperRoundedIcon />, onClick: () => handleNavigation('Articles') },
    { label: 'Avis', icon: <RateReviewIcon />, onClick: () => handleNavigation('avis') }
  ];

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(e, newValue) => {
        setValue(newValue);
      }}
      className="fixed bottom-0 w-full p-4"
      style={{ backgroundColor: "#e91e63", paddingTop: 20, paddingBottom: 30, zIndex:30 }}
    >
      {(menuType === 'simulateur' ? menuSimulateur : menuServices).map((item, index) => (
        <BottomNavigationAction
          key={index}
          style={{ color: 'white', fontFamily: "Merriweather, sans-serif", fontSize: 23 }}
          label={item.label}
          icon={item.icon}
          onClick={item.onClick}
        />
      ))}
    </BottomNavigation>
  );
}

export default CustomBottomNavigation;
