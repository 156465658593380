import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import Joyride from "react-joyride";
import { useNavigate } from "react-router-dom"; // Importer le hook useNavigate

const Alert = () => {
  const [visible, setVisible] = useState(false);
  const [tourActive, setTourActive] = useState(false);
  const navigate = useNavigate(); // Initialiser le hook useNavigate

  const closeAlert = () => {
    setVisible(false);
  };

  const startTour = () => {
    closeAlert();
    setTimeout(() => {
      setTourActive(true);
    }, 500); // Délai pour s'assurer que les éléments sont bien disponibles
  };

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <>
      <Joyride
        steps={[
          {
            target: ".step-1",
            content: "Cliquez ici pour ajouter le service qui vous convient à votre liste de budget, un calcul automatique sera effectué.",
            placement: "bottom",
            disableBeacon: true,
            spotlightPadding: 0,
            scrollTo: true,
          },
          {
            target: ".step-2",
            content: "Cliquez ici pour consulter ou gérer votre liste de Budget.",
            placement: "bottom",
            disableBeacon: true,
            spotlightPadding: 10,
            scrollTo: true,
          },
          // Ajoutez d'autres étapes ici
        ]}
        run={tourActive}
        continuous
        scrollToFirstStep={true}
        showSkipButton
        locale={{
          back: "Retour",
          close: "Fermer",
          last: "Dernier",
          next: "Suivant",
          skip: "Passer",
        }}
        styles={{
          options: {
            zIndex: 10000,
          },
          tooltipContainer: {
            borderRadius: '8px',
            padding: '10px',
            backgroundColor: '#f7f7f7',
            color: '#333',
            fontSize: '14px',
            maxWidth: '300px',
          },
          buttonBack: {
            backgroundColor: '#e0e0e0',
            color: '#333',
          },
          buttonNext: {
            backgroundColor: '#007bff',
            color: '#fff',
          },
        }}
        callback={(data) => {
          if (data.status === "finished" || data.status === "skipped") {
            setTourActive(false);
          }
        }}
      />

      {visible && (
        <div className="fixed bottom-4 right-4 bg-white border border-gray-300 shadow-lg rounded-lg p-4 z-50 flex flex-col space-y-4" style={{margin:20}}>
          <div className="flex items-start justify-between w-full">
            <span className="text-sm text-gray-700">
              Bienvenue sur notre site ! <br />
              Pour améliorer notre service, merci de remplir le formulaire  <a 
                href="https://docs.google.com/forms/u/1/d/e/1FAIpQLSeD5niFBOtyYWtMZ62d-cjxhW_8M9YxfqDrVaG8-Y47Dq78Kg/viewform" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-blue-500 hover:text-blue-700 underline"
              >
                en cliquant ici
              </a>.
              <br />
              Votre avis est précieux.
            </span>
            <button onClick={closeAlert} className="text-red-500 hover:text-red-700 ml-4">
              <FaTimes size={20} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Alert;
