import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaImage, FaCoins, FaHeart, FaCity } from "react-icons/fa";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import WarningIcon from "@mui/icons-material/Warning";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Bars } from "react-loader-spinner";
import Swal from "sweetalert2";
import Sidebar from "../Sidebar";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import CustomBottomNavigation from "../common/BottomNavigationAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Communes = () => {
  const [communes, setCommunes] = useState([]);
  const [selectedCategory, setSelectedCommune] = useState(null);
  const [annonces, setAnnonces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [wishlist, setWishlist] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const perPage = 10; // Nombre d'annonces par page

  const encodeMessage = (message) => {
    return encodeURIComponent(message);
  };

  const createWhatsAppLink = (phone, message) => {
    return `https://wa.me/${phone}?text=${encodeMessage(message)}`;
  };

  const domaine = process.env.REACT_APP_SITE_DOMAINE;

  const [adData, setAdData] = useState({
    image_url: "",
    publicite_url: "",
    statut: "",
  });
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  // Nouvel état pour le coût

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const toggleWishlist = (annonce) => {
    const isInWishlist = wishlist.some((item) => item.id === annonce.id);

    if (isInWishlist) {
      Swal.fire({
        title: "Vraiment ?",
        text: "Voulez-vous vraiment retirer cette annonce de votre panier ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, retirer !",
        cancelButtonText: "Non conserver",
        cancelButtonColor: "#333",
      }).then((result) => {
        if (result.isConfirmed) {
          const updatedWishlist = wishlist.filter(
            (item) => item.id !== annonce.id
          );

          // Sauvegarder la liste de souhaits mise à jour dans le localStorage
          localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));

          // Afficher une alerte pour confirmer que l'élément a été retiré
          Swal.fire(
            "Dommage !",
            "L'annonce a été supprimée de votre panier.",
            "success"
          );

          setWishlist(updatedWishlist);
        }
      });
    } else {
      const updatedWishlist = [...wishlist, annonce];

      // Sauvegarder la liste de souhaits dans le localStorage
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));

      // Afficher une alerte pour confirmer que l'élément a été ajouté
      Swal.fire({
        title: "Parfait !",
        text: `l'annonce "${annonce.title}" a été ajoutée à la liste des éléments qui constituent votre budget !`,
        icon: "success",
        confirmButtonText: "OK",
      });

      setWishlist(updatedWishlist);
    }
  };

  // Charger les éléments de la liste de souhaits depuis le localStorage
  useEffect(() => {
    const savedWishlist = localStorage.getItem("wishlist");
    if (savedWishlist) {
      setWishlist(JSON.parse(savedWishlist));
    }
  }, []);

  const formatNumber = (value) => {
    if (!value) return "";
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const parseNumber = (value) => {
    return value.replace(/\s+/g, "");
  };

  const truncateText = (text, length = 20) => {
    if (text.length <= length) return text;
    return `${text.substring(0, length)}...`;
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/bycommunes/`;
    axios
      .get(url)
      .then((response) => {
        setCommunes(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des catégories:", error);
      });
  }, []);

  useEffect(() => {
    const fetchAnnonces = async () => {
      try {
        setLoading(true);

        // Vérification de l'ajout correct de la catégorie sélectionnée dans la requête
        const communeQuery = selectedCategory
          ? `&commune=${selectedCategory}`
          : "";

        const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/annonces?page=${currentPage}&per_page=${perPage}${communeQuery}`;
        console.log("URL des annonces:", url); // Debugging: vérifier l'URL générée

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des annonces");
        }
        const data = await response.json();

        setAnnonces(data.data || []);
        setTotalPages(data.pagination ? data.pagination.total_pages : 1);
        setTotalResults(data.pagination ? data.pagination.total_items : 0);
      } catch (error) {
        console.error("Erreur lors de la récupération des annonces:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedCategory) {
      fetchAnnonces(); // Charger les annonces pour la catégorie sélectionnée
    } else {
      setAnnonces([]); // Réinitialiser les annonces si aucune catégorie n'est sélectionnée
    }
  }, [selectedCategory, currentPage]);

  const handleCommuneClick = (communeName) => {
    setSelectedCommune(communeName);
    setCurrentPage(1); // Réinitialiser à la première page lors du changement de catégorie
  };

  useEffect(() => {
    const fetchData = async () => {
      // Simule un chargement de 2 secondes
      setTimeout(() => {
        setInitialLoading(false); // Arrêter le chargement initial après 2 secondes
      }, 1000);
    };

    fetchData();
  }, []); // Inclure un tableau de dépendances vide pour n'exécuter qu'une fois

  return (
    <>
      {initialLoading ? (
        <div className="loading-overlay">
          <Bars
            height="100"
            width="100"
            color="#e91e63"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="bars-wrapper"
          />
        </div>
      ) : (
        <>
          <div
            className="container"
            style={{
              marginTop: -5,
              overflowY: "auto",
              minHeight: "100vh",
              background: "white",
            }}
          >
            <div
              className="grid-cols-12  md:grid-cols-12 lg:grid-cols-12 lg:pl-10 lg:pr-10"
              style={{ background: "white", marginTop: 100 }}
            >
            {/*
              <p
                className="items-center flex"
                style={{
                  marginLeft: 20,
                  fontWeight: "bolder",
                  fontSize: 17,
                  marginTop: 30,
                  marginBottom: 40,
                }}
              >
                <FaCity style={{ color: "#e91e63" }} className="mr-2" />{" "}
                services proposés par communes
              </p>
             */}
            </div>
            {/* Affichage des catégories */}
            <div className="grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-4 lg:pl-10 lg:pr-10" style={{ margin: 10, cursor: 'pointer' }}>
  {communes.map(commune => (
    <div
      key={commune.id}
      className="relative flex flex-col items-center p-4 rounded-lg shadow-md cursor-pointer transition-transform transform hover:scale-105"
      onClick={() => handleCommuneClick(commune.name)}
      style={{
        boxShadow: '0px 1px 10px -2px #828282',
        backgroundImage: `url(${commune.icon})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: 'white',
        height: '150px', // Augmente la hauteur de chaque carte
      }}
    >    
      {/* Overlay sombre */}
      <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>

      {/* Contenu de la carte */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full">
        <p className="text-lg font-semibold text-white-500 mb-2">{commune.name}</p>
        {/* <p className="text-sm text-gray-600 mb-2">{truncateText(commune.description, 50)}</p> */}
        <p className="text-sm text-white-500">({commune.count})</p>
      </div>
    </div>
  ))}
</div>

            {loading ? (
              <div className="loading-overlay">
                <Bars
                  height="100"
                  width="100"
                  color="#e91e63"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass="bars-wrapper"
                />
              </div>
            ) : (
              <>
                <Sidebar />
                {/* Affichage des résultats */}

                {totalResults !== 0 ? (
                  <>
                    <div
                      className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 lg:pl-10 lg:pr-10"
                      style={{ background: "white", marginTop: 40 }}
                    >
                      <p
                        style={{
                          marginLeft: 20,
                          fontWeight: "bolder",
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                        className="text-[17px] md:text-[20px] lg:text-[19px]"
                      >
                        {" "}
                        <LocalOfferIcon style={{ color: "#e91e63" }} />{" "}
                        {totalResults}{" "}
                        {totalResults > 1
                          ? "annonces trouvés"
                          : "annonce trouvée"}{" "}
                      </p>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {selectedCategory && annonces.length === 0 && !loading && (
                  <div
                    className="grid gap-4 lg:pl-10 lg:pr-10"
                    style={{ background: "white" }}
                  >
                    <p
                      style={{
                        marginLeft: 20,
                        fontWeight: "bolder",
                        fontSize: 18,
                        marginTop: 20,
                        marginBottom: 20,
                        color: "#E91E63",
                      }}
                    >
                      {" "}
                      <WarningIcon /> Aucun résultat trouvé pour cette recherche
                    </p>
                  </div>
                )}

                {annonces.length > 0 && (
                  <>
                <div
                  className="grid grid-cols-1 gap-0 md:grid-cols-2 lg:grid-cols-4 lg:pl-10 lg:pr-10"
                  style={{ background: "white" }}
                >
                  {annonces.map((annonce) => (
                    <div
                      key={annonce.id}
                      className="card shadow-lg rounded-lg flex flex-col gap-5 p-0 bg-white m-4"
                      style={{
                        //boxShadow: "0 2px 8px #00000026",
                        boxShadow: "0px 1px 10px -2px #828282",
                        borderRadius: ".5rem",
                      }}
                    >
                      <Link to={`/details/${annonce.slug}`}>
                      <div className="w-full h-64 sm:h-80 md:h-96 lg:h-[200px] overflow-hidden flex justify-center items-center bg-gray-100 relative">
                          <img
                            src={
                              annonce.featured_image ||
                              "https://via.placeholder.com/150"
                            }
                            alt={annonce.title}
                            className="w-full h-full object-cover rounded-lg "
                          />

                          {/* Étiquette de la catégorie */}
                <div
                  className="absolute top-2 right-2 bg-pink-500 text-white py-1 px-2 rounded-lg text-sm font-semibold"
                  style={{ zIndex: 10 }}
                >
                  {annonce.categories}
                </div>
                        </div>
                      </Link>
                      <h2
                        className="text-lg font-medium pl-5 pr-5 pb-5"
                        style={{ fontWeight: 700, fontSize: "17px" }}
                      >
                        <Link to={`/details/${annonce.slug}`}>
                          {/*truncateText(annonce.title, 10)*/}
                          {annonce.title}
                        </Link>

                        <div
                          className="flex items-center"
                          style={{
                            fontWeight: 400,
                            color: "#6c6c6c",
                            fontSize: 15,
                          }}
                        >
                          <FaCoins className="mr-2" />
                          {annonce.acf_fields.cout
                            ? `${parseInt(
                                annonce.acf_fields.cout
                              ).toLocaleString()} F CFA`
                            : "Non spécifié"}
                          <button
                            className={`wishlist-button ${
                              wishlist.some((item) => item.id === annonce.id)
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              e.stopPropagation(); // Empêche le clic de passer au Link
                              toggleWishlist(annonce);
                            }}
                            style={{
                              margin: "0 1rem",
                              background: "none",
                              border: "none",
                            }}
                          >
                            <FaHeart
                              style={{
                                color: wishlist.some(
                                  (item) => item.id === annonce.id
                                )
                                  ? "#E91E63"
                                  : "grey",
                                fontSize: "1.5rem",
                              }}
                              className="step-1"
                            />
                          </button>
                        </div>

                        <div
                          className="flex items-center"
                          style={{
                            fontWeight: 800,
                            color: "#E91E63",
                            fontSize: 15,
                          }}
                        >
                          <PersonOutlineOutlinedIcon className="mr-2" />
                          par {annonce.author_name || "Inconnu"}
                          
                        </div>
                        <div
                          className="flex items-center"
                          style={{
                            fontWeight: 800,
                            color: "#128c7e",
                            fontSize: 15,
                          }}
                        >
                          <FaCity className="mr-2" />
                          service à {annonce.communes || "Inconnu"}
                          {/*  <span className="ml-4 items-center flex" style={{fontSize:10, color:'green'}}><FaCheckDouble/> identité vérifiée</span> */}
                        </div>

                        <div
                          className="flex justify-center items-center"
                          style={{
                            fontWeight: 800,
                            color: "white",
                            fontSize: 15,
                            background: "#075E54",
                            padding: "4px",
                            borderRadius: 4,
                            marginTop: 5,
                          }}
                        >
                          {annonce.acf_fields.cout ? (
                            <a
                              href={createWhatsAppLink(
                                annonce.acf_fields.telephone,
                                `Bonjour Madame/Monsieur ${annonce.author_name}, j'ai trouvé votre service sur WEDDAPP.CI, lien : ${`${process.env.REACT_APP_SITE_DOMAINE}/details/${annonce.slug}`}, je suis intéressé.`
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="whatsapp-link"
                            >
                              <FontAwesomeIcon icon={faWhatsapp} /> écrire sur whatsapp
                              
                            </a>
                          ) : (
                            "Non spécifié"
                          )}
                        </div>
                      </h2>
                    </div>
                  ))}
                </div>

                <div
                  className="flex flex-col space-y-4 pt-8"
                  style={{ background: "white" }}
                >
                  <div className="text-center" style={{ background: "white" }}>
                    <div className="" style={{ marginBottom: "30px" }}>
                      <button
                        className="btn-primary text-white bg-pink-500 rounded-lg hover:shadow-lg disabled:opacity-50"
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        style={{
                          marginRight: "5px",
                          padding: 8,
                          fontSize: 16,
                          fontWeight: "bolder",
                        }}
                      >
                        Précédent
                      </button>
                      <span
                        style={{
                          margin: "0 5px",
                          fontSize: 15,
                          fontWeight: "bolder",
                        }}
                      >
                        Page {currentPage} sur {totalPages}
                      </span>
                      <button
                        className="btn-primary text-white bg-pink-500 rounded-lg hover:shadow-lg disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        style={{
                          marginLeft: "5px",
                          padding: 8,
                          fontSize: 16,
                          fontWeight: "bolder",
                        }}
                      >
                        Suivant
                      </button>
                    </div>
                  </div>
                </div>
              </>
                )}

                <div
                  className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 lg:pl-32 lg:pr-32"
                  style={{ background: "white" }}
                >
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </>
            )}
          </div>
        </>
      )}
      <CustomBottomNavigation />
    </>
  );
};

export default Communes;
