import React, { useEffect, useState } from "react";
import { Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Posts from './Posts';
import Simulateur from './Home/Simulateur';
import Navbar from "../common/Navbar";
import Single from "./Single";
import CategoryArticles from "../Postscat";
import Footer from "../Footer";
import AboutPage from "../AboutPage";
import Annonces from "../Annonces";
import MyFullCalendar from "../MyFullCalendar";
import SujetPriereForm from "../Devis";
import SingleBySlug from "./Single/SingleBySlug";
import AnnoncesBySlug from "./Single/AnnoncesBySlug";
import AnnoncesByUser from "./Single/AnnoncesByUser";
import Details from "./Single/Details";
import SingleById from "./Single/SingleById";
import RegisterForm from "../Register";
import Devis from "../Devis";
import Login from "../Login";
import Categories from "../Categories";
import Boutique from "../Boutiques";
import Communes from "../Communes";
import { Bars } from 'react-loader-spinner'; // Import du composant d'animation
import VideoBackground from "./VideoBackground";


const Pages = () => {
  const [homeComponent, setHomeComponent] = useState(null);
  const [loading, setLoading] = useState(true); // État pour le chargement

  useEffect(() => {
    // Consommez l'API pour déterminer la page d'accueil
    const url = `${process.env.REACT_APP_API_ROOT_V1}/homepage`;
    axios.get(url)
      .then(response => {
        // Assurez-vous de la structure correcte des données
        const status = response.data.status; // Accédez au champ "status"
        const component = status === 'simulateur' ? <Simulateur /> : <Annonces />;
        setHomeComponent(component);
        setLoading(false); // Données chargées
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des données de la page d'accueil :", error);
        // Valeur par défaut en cas d'erreur
        setHomeComponent(<Annonces />);
        setLoading(false); // Données chargées malgré l'erreur
      });
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Bars
          height="80"
          width="80"
          color="#E91E63"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    ); // Affiche l'animation de chargement pendant que les données sont en cours de récupération
  }

  return (
    <>
      <VideoBackground />
      <Navbar />
      <Routes>
        <Route path="/" element={homeComponent} />
        <Route path="/articles" element={<Posts />} />
        <Route path="/simulateur" element={<Simulateur />} />
        <Route path="/presentation" element={<AboutPage />} />
        <Route path="/post/:id" element={<SingleById />} />
        <Route path="/details/:slug" element={<Details />} />
        <Route path="/:slug" element={<SingleBySlug />} />
        <Route path="/calendrier" element={<MyFullCalendar />} />
        <Route path="/demande-devis" element={<Devis />} />
        <Route path="/articles/:categoryId" element={<CategoryArticles />} />
        <Route path="/creer-son-compte-weddapp" element={<RegisterForm />} />
        <Route path="/connexion" element={<Login />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/communes" element={<Communes />} />
        <Route path="/services" element={<Annonces />} />
        <Route path="/boutique/:username" element={<AnnoncesByUser />} />
        <Route path="/boutiques/" element={<Boutique/>} />
      </Routes>
      {/* <Footer/> */}
    </>
  );
};

export default Pages;
