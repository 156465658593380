import React, { useState, useEffect, useRef } from 'react';
import { FaCogs, FaHeart, FaTrash, FaDownload, FaList, FaListAlt, FaPen, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import FavoriteIcon from '@mui/icons-material/Favorite';

const Sidebar = () => {
  const [wishlist, setWishlist] = useState([]);
  const [adData, setAdData] = useState({ image_url: '', publicite_url: '', statut: '' });
  const sidebarRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [animate, setAnimate] = useState(false);
  const audioRef = useRef(null); // Référence pour le son



  const wishlistRef = useRef(wishlist.length);

  useEffect(() => {
    if (wishlist.length !== wishlistRef.current) {
      setAnimate(true);
      wishlistRef.current = wishlist.length;
      const timeout = setTimeout(() => setAnimate(false), 300); // Reset animation state after 300ms      
      return () => clearTimeout(timeout);
    }
  }, [wishlist.length]);


  const encodeMessage = (message) => {
    return encodeURIComponent(message);
  };

  // Fonction pour créer le lien WhatsApp
  const createWhatsAppLink = (phone, message) => {
    return `https://wa.me/${phone}?text=${encodeMessage(message)}`;
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const loadWishlist = () => {
    const savedWishlist = localStorage.getItem('wishlist');
    if (savedWishlist) {
      setWishlist(JSON.parse(savedWishlist));
    } else {
      setWishlist([]);
    }
  };

  const fetchAdData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/myplugin/v1/derniere-publicite`;
      const response = await fetch(url);
      const data = await response.json();
      return {
        image_url: data.image_url || '',
        publicite_url: data.publicite_url || '',
        statut: data.statut || ''
      };
    } catch (error) {
      console.error('Erreur lors de la récupération des données de la publicité:', error);
      return { image_url: '', publicite_url: '', statut: '' };
    }
  };

  useEffect(() => {
    const getAdData = async () => {
      const data = await fetchAdData();
      setAdData(data);
    };

    getAdData();
  }, []);

  useEffect(() => {
    loadWishlist();
    const intervalId = setInterval(loadWishlist, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const removeFromWishlist = (id) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: "Vous ne pourrez pas annuler cette action!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#333',
      confirmButtonText: 'Oui, supprimez-le!',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedWishlist = wishlist.filter(item => item.id !== id);
        setWishlist(updatedWishlist);
        localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
        Swal.fire(
          'Supprimé!',
          'L\'élément a été supprimé.',
          'success'
        );
      }
    });
  };

  const calculateTotalCost = () => {
    return wishlist.reduce((total, item) => {
      const cost = parseFloat(item.acf_fields.cout) || 0;
      return total + cost;
    }, 0);
  };

  return (
    <div style={styles.container}>
      <button onClick={toggleSidebar} style={styles.gearButton}>
      <button onClick={toggleSidebar} style={styles.gearButton}>
  <div style={styles.cartIconContainer}>
  <FavoriteIcon
            size={40}
            style={{ color: '#E91E63', fontSize: 40 }}
            className={`step-2 ${animate ? 'animate-zoom' : ''}`}
          />

    <div style={styles.badge}>
      {wishlist.length}
    </div>
  </div>
</button>

      </button>

      {isSidebarOpen && (
        <div ref={sidebarRef} style={styles.sidebar}>
          <br/>

          {adData.statut.toLowerCase() === 'active' && (
            <div className="grid lg:pl-10 lg:pr-10">
              <center>
                <a href={adData.publicite_url} target="_blank" rel="noopener noreferrer">
                  <img
                    src={adData.image_url}
                    alt="Logo publicitaire"
                    style={{ width: "90%", marginTop: "2%", marginBottom: "4%" }}
                  />
                </a>
              </center>
            </div>
          )}

          <h3 className='items-center flex text-[20px] md:text-[20px] lg:text-[25px]' style={{color:'#E91E63', fontWeight:'bolder'}}>
            <FaUser className='mr-2'/> Ma Liste de Budget
          </h3>                    
          <h3 className='items-center flex' style={{color:' #128c7e', fontSize:15, fontWeight:'bolder', marginTop:10}}>
            {wishlist.length} {wishlist.length > 1 ? 'éléments' : 'élément'}
          </h3>

          <p style={styles.totalCost}>
            Total : {calculateTotalCost().toLocaleString('fr-FR', { style: 'currency', currency: 'XOF', marginTop:0 })}
          </p>
          {wishlist.length > 0 ? (
            <ul style={styles.list}>
              {wishlist.map(item => (
                <li key={item.id} style={styles.listItem}>
                <Link to={`/details/${item.slug}`} style={styles.link} className='text-[12px] md:text-[12px] lg:text-[15px]'>
                  <img src={item.featured_image} alt={item.title} style={styles.image} />
                  </Link>
                  <div style={styles.itemContent}>                    
                  <Link to={`/details/${item.slug}`} style={styles.link} className='text-[12px] md:text-[12px] lg:text-[15px]'>
                    <span style={styles.categories}>
                      {item.categories.join(', ')}
                    </span>
                    </Link><br/>
                    <Link to={`/details/${item.slug}`} style={styles.link} className='text-[12px] md:text-[12px] lg:text-[15px]'>
                      {parseFloat(item.acf_fields.cout).toLocaleString('fr-FR')} F CFA
                    </Link><br/>
                    {item.acf_fields.telephone && (
                      <p style={styles.telephone} className='text-[14px] md:text-[14px] lg:text-[14px]'>
                        
                        <a
                              href={createWhatsAppLink(
                                item.acf_fields.telephone,
                                `Bonjour Madame/Monsieur ${item.author_name}, j'ai trouvé votre service sur WEDDAPP.CI, lien : ${`${process.env.REACT_APP_SITE_DOMAINE}/details/${item.slug}`}, je suis intéressé.`
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="whatsapp-link"
                              style={{fontSize:13, color:'#128c7e', fontWeight:'bolder'}}
                            >
                              <FontAwesomeIcon icon={faWhatsapp} /> 
                              &nbsp;WhatsApp
                            </a>

                      </p>
                    )}
                  </div>
                  <button
                    onClick={() => removeFromWishlist(item.id)}
                    style={styles.removeButton}
                    className='flex items-center'
                    data-hide-on-screenshot
                  >
                    <FaTrash className='mr-2' />
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>Votre liste de souhaits est vide.</p>
          )}
          
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    position: 'fixed',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 1000
  },
  gearButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    position: 'relative',
  },
  cartIconContainer: {
    position: 'relative',
  },
  badge: {
    position: 'absolute',
    top: '-14px',
    right: '10px',
    backgroundColor: '#E91E63',
    color: '#fff',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
  },
  sidebar: {
    width: '100%',
    height: '100vh',
    backgroundColor: "white",
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    padding: '20px',
    overflowY: 'auto',
    position: 'relative'
  },
  list: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    boxShadow: '0 2px 8px #00000026',
    padding: '5px',
    borderRadius: '.5rem',
    
  },
  logo: {
    alignItems: 'center',
    marginBottom: '10px',
    boxShadow: '0 2px 8px #00000026',
    padding: '1rem',
    borderRadius: '.5rem',
    background:''
  },
  image: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '8px',
    marginRight: '10px',
  },
  itemContent: {
    flex: 1,
    overflow: 'hidden',
  },
  link: {
    textDecoration: 'none',
    color: '#007bff',
    fontWeight:'bolder',
    fontSize:13
  },
  categories: {
    fontSize: '14px',
    color: 'black',
    borderRadius:2,
    padding:3,
    fontWeight:'bolder'
  },
  telephone: {
    marginTop: '5px',
  },
  removeButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#E91E63',
  },
  totalCost: {
    fontWeight: 'bolder',
    fontSize: '18px',
    marginBottom: '10px',
  },
};

export default Sidebar;
